.button {
    background: #DA2424;
    border: 0;
    border-radius: 50px;
    color: #fff;
    font-family: 'Source Sans 3', sans-serif;
    font-size: 18px;
    display: block;
    margin: 0 auto;
    padding: 10px 20px;
    width: 300px;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    transition: all ease-in .2s;

    &:hover {
        cursor: pointer;
        background: #bd1c1c;
        transform: scale(1.03);
    }

    /* Tablet - Desktop */
    @media only screen and (min-width: 768px) {
        width: 250px;
    }
}
