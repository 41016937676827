.app {
    width: 100%;
    margin: 0 auto;

    /* Tablet - Desktop */
    @media only screen and (min-width: 768px) {
        width: 700px;
    }
}

.title {
    font-family: Valeska, serif;
    font-size: 65px;
    font-weight: 500;
    line-height: 145px;
    color: #ffffff;
    text-align: center;
    text-shadow: 0 2px 4px rgb(0, 0, 0, 0.4);
}

.description {
    font-family: 'Source Sans 3', sans-serif;
    font-size: 22px;
    line-height: 30px;
    color: #515151;
    text-align: center;
    margin: 0 12px;

    /* Tablet - Desktop */
    @media only screen and (min-width: 768px) {
        margin: 0;
    }
}

.button__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0;
    position: relative;
    z-index: 30;
}


.teaLabel__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.animated-cup-of-tea__wrapper {
    overflow: hidden;
    height: 470px;
}
