.cup-wrap {
    width: 100%;
    height: 600px;
    position: relative;
    margin: 0 auto;
    z-index: 5;
    top: -150px;
    overflow: hidden;

    /* Tablet - Desktop */
    @media only screen and (min-width: 768px) {
        width: 500px;
    }
}

.cup {
    position: absolute;
    bottom: 0;
    left: 30px;

    img {
        max-width: 95%;
        width: 95%;
    }

    /* Tablet - Desktop */
    @media only screen and (min-width: 768px) {
        left: 50px;

        img {
            max-width: 100%;
            width: 100%;
        }
    }
}

.smoke-wrap {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 222px;
}

.smoke,
.smoke2,
.smoke3 {
    filter: blur(5px);
    transform-origin: 50% 50%;
}

.smoke {
    animation: smoke1 3s linear infinite;
    animation-delay: 0.5s;
}

.smoke2 {
    animation: smoke2 3s linear infinite;
    animation-delay: 1.5s;
}

.smoke3 {
    width: 500px;
    animation: smoke3 4s linear infinite;
    animation-delay: 2.5s;
}

@keyframes smoke1 {
    0% {
        filter: blur(0px);
        transform: translateY(0px) scale(-1, 1);
        opacity: 0;
    }

    25% {
        filter: blur(3px);
        transform: translateY(-10px) scale(-1, 1.05);
        opacity: 0.5;
    }

    50% {
        filter: blur(5px);
        transform: translateY(-20px) scale(-1, 1.1);
        opacity: 1;
    }

    75% {
        filter: blur(5px);
        transform: translateY(-30px) scale(-1, 1.15);
        opacity: 0.5;
    }

    100% {
        filter: blur(7px);
        transform: translateY(-40px) scale(-1, 1.2);
        opacity: 0;
    }
}

@keyframes smoke2 {
    0% {
        filter: blur(0px);
        transform: translateY(0px) scale(1);
        opacity: 0;
    }

    25% {
        filter: blur(3px);
        transform: translateY(-10px) scale(1.05);
        opacity: 0.5;
    }

    50% {
        filter: blur(5px);
        transform: translateY(-20px) scale(1.1);
        opacity: 1;
    }

    75% {
        filter: blur(5px);
        transform: translateY(-30px) scale(1.15);
        opacity: 0.5;
    }

    100% {
        filter: blur(7px);
        transform: translateY(-40px) scale(1.2);
        opacity: 0;
    }
}

@keyframes smoke3 {
    0% {
        filter: blur(0px);
        transform: translateY(0px) scale(1);
        opacity: 0;
    }

    25% {
        filter: blur(3px);
        transform: translateY(-20px) scale(1.05);
        opacity: 0.5;
    }

    50% {
        filter: blur(5px);
        transform: translateY(-40px) scale(1.1);
        opacity: 1;
    }

    75% {
        filter: blur(5px);
        transform: translateY(-60px) scale(1.15);
        opacity: 0.5;
    }

    100% {
        filter: blur(7px);
        transform: translateY(-80px) scale(1.2);
        opacity: 0;
    }
}
