html {
    font-size: 100%;
    font-weight: normal;
    height: 100%;
    line-height: 1.5;
    min-height: 100%;
    position: relative;
    scroll-behavior: smooth;
}

body {
    font-size: 16px;
    line-height: 24px;
    background-color: #E0D5C1;
    background-image: url("images/wallpaper_1670x1670_1705762880536.png");
    background-size: 144%;
    color: #565656;
    height: 100%;
    min-height: 100%;

    /* IE11 fix */
    @media all and (-ms-high-contrast: none) {
        display: block;
    }

    /* Tablet - Desktop */
    @media only screen and (min-width: 768px) {
        background-size: 66%;
    }
}
