.tealabel {
    align-items: center;
    justify-content: center;
    background: rgb(188, 200, 151);
    background: linear-gradient(180deg, rgba(188, 200, 151, 1) 0%, rgba(158, 174, 111, 1) 100%);
    color: #DA2424;
    display: flex;
    font-family: 'Source Sans 3', sans-serif;
    font-size: 33px;
    height: 328px;
    line-height: 50px;
    padding: 30px;
    position: relative;
    text-align: center;
    width: 380px;
    z-index: 10;

    &::before {
        background: #fff;
        content: "";
        display: block;
        position: absolute;
        top: 0;
        z-index: 20;
        width: 140px;
        height: 20px;
        opacity: .25;
    }

    &::after {
        background: #fff;
        content: "";
        display: block;
        position: absolute;
        z-index: 20;
        width: 92%;
        height: 91%;
    }
}

.text {
    position: relative;
    z-index: 40;
}
